@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Berkeley Mono';
  src: url('../public/fonts/BerkeleyMono-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Berkeley Mono';
  src: url('../public/fonts/BerkeleyMono-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

body {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes textShuffle {
  0% {
    opacity: 0.7;
  }
  20% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.text-shuffle {
  animation: textShuffle 0.1s ease-in-out infinite;
}

.grid-background {
  background-size: 80px 80px;
  background-image: 
    linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-position: top center;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,0.10), black, rgba(0,20,0,0.25));
}

/* Optional: Hide scrollbar but keep functionality */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Global scrollbar styles */
::-webkit-scrollbar {
  width: 1px !important; /* Thinner width */
  background: #991919 !important; /* Off-black charcoal */
}

::-webkit-scrollbar-track {
  background: #991919 !important; /* Off-black charcoal */
}

::-webkit-scrollbar-thumb {
  background: rgba(11, 255, 198, 1) !important; /* Brand green with lower opacity */
  border-radius: 0 !important;
  border: none !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #991919 !important; /* Slightly more visible on hover */
}

/* Firefox */
* {
  scrollbar-width: none !important;
  scrollbar-color: transparent transparent !important;
}

/* Ensure mobile styles don't override */
@media (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 1px !important;
    display: block !important;
  }
}

/* Hide scrollbar on mobile */
@media (max-width: 767px) {
  /* Disable all snap scrolling on mobile */
  .snap-y,
  .snap-mandatory,
  main,
  section,
  * {
    scroll-snap-type: none !important;
    scroll-snap-align: none !important;
    scroll-snap-stop: normal !important;
  }
  
  /* Reset main container on mobile */
  main {
    height: auto;
    overflow-y: auto;
    position: relative;
  }
  
  /* Reset section styles on mobile */
  section {
    min-height: 100vh;
    min-height: 100svh;
    scroll-snap-align: none;
    scroll-snap-stop: normal;
  }
  
  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
}

.snap-y {
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  height: 100%;
  position: relative;
}

/* Add touch-specific optimisations */
@supports (-webkit-touch-callout: none) {
  .snap-y {    
    scroll-padding: 5rem; 
  }
}

.snap-start {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.scroll-smooth {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 1px;
  overscroll-behavior-y: contain;
}

/* Add momentum-based scrolling for iOS */
@supports (-webkit-overflow-scrolling: touch) {
  main {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
  }
}

/* Main container styles - HomePage only */
.snap-container main {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  scroll-snap-type: y mandatory;
  position: relative;
}

/* Section styles */
section {
  min-height: 100vh;
  min-height: 100svh;
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* Ensure content is above background */
section > * {
  position: relative;
  z-index: 2;
}

/* Mobile optimization */
@media (max-width: 768px) {
  main {
    scroll-padding-top: 1rem;
  }
  
  section {
    min-height: 100vh;
    min-height: 100svh;
    padding: 2rem 0;
  }
}

/* Guide Lines */
.guide-lines {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 1;
}

/* Vertical lines */
.guide-lines::before,
.guide-lines::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(22, 176, 139, 0.1) 15%,
    rgba(22, 176, 139, 0.1) 85%,
    transparent
  );
}

.guide-lines::before {
  left: 8%;
}

.guide-lines::after {
  right: 8%;
}

/* Horizontal lines */
.guide-lines .horizontal-line {
  position: absolute;
  left: 8%;
  right: 8%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(22, 176, 139, 0.1) 15%,
    rgba(22, 176, 139, 0.1) 85%,
    transparent
  );
}

.guide-lines .horizontal-line-top {
  top: 5%;
}

.guide-lines .horizontal-line-bottom {
  bottom: 5%;
}

/* Horizontal Guides */
.horizontal-guides {
  position: fixed;
  inset: 0;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  pointer-events: none;
  z-index: 1;
}

.horizontal-guides > div {
  border-bottom: 1px solid rgba(75, 75, 75, 0.10);
  width: 100%;
  height: 100%;
  background-color: rgba(4, 5, 4, 0.1);
}

@supports (-webkit-touch-callout: none) {
  html, body {
    height: -webkit-fill-available;
  }
  
  main {
    min-height: -webkit-fill-available;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Add this to ensure content stays above the lines */
main > div, 
section, 
.container {
  position: relative;
  z-index: 2;
}

@media (max-width: 640px) {
  .snap-y {
    scroll-snap-type: none;
  }
  
  .snap-start {
    min-height: 100vh; /* Ensure minimum full height */
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Add after your existing styles */
.snap-mandatory {
  scroll-snap-type: y mandatory;
}

@keyframes noise {
  0% { transform: translate3d(0, 9rem, 0) scale3d(0.95, 0.95, 1); }
  15% { transform: translate3d(-2rem, -3rem, 0) scale3d(1, 1, 1); }
  30% { transform: translate3d(3rem, 1rem, 0) scale3d(0.98, 0.98, 1); }
  45% { transform: translate3d(-3rem, 3.5rem, 0) scale3d(0.97, 0.97, 1); }
  60% { transform: translate3d(3rem, -2rem, 0) scale3d(1.01, 1.01, 1); }
  75% { transform: translate3d(-2rem, 3rem, 0) scale3d(0.99, 0.99, 1); }
  90% { transform: translate3d(2rem, -2rem, 0) scale3d(0.98, 0.98, 1); }
  100% { transform: translate3d(0, 9rem, 0) scale3d(0.95, 0.95, 1); }
}

.bg-noise {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 256 256' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)'/%3E%3C/svg%3E");
  animation: noise 8s steps(1) infinite;
}

/* Keyframe Animations */
@keyframes grain {
  0%, 100% { transform: translate(0, 0); }
  10% { transform: translate(-0.2%, -0.2%); }
  20% { transform: translate(-0.4%, 0.4%); }
  30% { transform: translate(0.4%, -0.4%); }
  40% { transform: translate(-0.4%, 0.2%); }
  50% { transform: translate(0.2%, -0.2%); }
  60% { transform: translate(0.4%, 0.4%); }
  70% { transform: translate(0.2%, -0.4%); }
  80% { transform: translate(-0.2%, 0.2%); }
  90% { transform: translate(0.2%, 0.4%); }
}

.grain-overlay {
  position: fixed;
  inset: -200%;
  width: 400%;
  height: 400%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 2048 2048' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='2.5' numOctaves='5' stitchTiles='stitch'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3CfeComponentTransfer%3E%3CfeFuncR type='gamma' amplitude='0.7' exponent='1.5'/%3E%3CfeFuncG type='gamma' amplitude='0.7' exponent='1.5'/%3E%3CfeFuncB type='gamma' amplitude='0.7' exponent='1.5'/%3E%3C/feComponentTransfer%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  opacity: 1;
  animation: grain 0.4s steps(1) infinite;
  pointer-events: none;
  mix-blend-mode: overlay;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

/* Reset scroll behavior for mobile */
@media (max-width: 768px) {
  html {
    scroll-behavior: auto;
  }
}

/* About page specific styles - separate from HomePage styles */
.about-page-container {
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.about-page-container::-webkit-scrollbar {
  display: none;
}

.about-page {
  background-color: #040604;
  min-height: 100%;
  width: 100%;
  position: relative;
}

/* About page specific styles */
.scrollbar-none {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

/* Override iOS-specific styles for non-homepage */
@supports (-webkit-touch-callout: none) {
  .about-page-container main {
    position: static;
    height: auto;
    min-height: auto;
  }
}

/* Reset main styles for non-homepage */
div:not(.snap-container) > main {
  height: auto;
  position: relative;
  overflow: visible;
  scroll-snap-type: none;
}

.snap-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.snap-container section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

@media (max-width: 768px) {
  .snap-container {
    scroll-snap-type: none;
  }
}

/* Add specific handling for the homepage container */
#main-container {
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

#main-container::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}


@media (max-width: 768px) {
  #main-container {
    scroll-snap-type: none;
  }
}